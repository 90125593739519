import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

type Props = {};

const Projects = (props: Props) => {
  return (
    <>
      <a className="anchor" id="projects"></a>
      <div className="mt-9 md:mt-16 font-inter">
        {/* Whole Grid */}
        <div className=" w-full flex flex-col space-y-3 ">
          {/* Upper Row */}
          <div className="flex flex-col md:flex-row space-y-3 md:space-x-3 md: md:space-y-0">
            {/* #1 */}
            <Link
              className="h-96 lg:h-[28rem] group w-full cursor-pointer overflow-hidden relative hover:scale-100 transition ease-in-out duration-500"
              to="/projects/crowdhaus/"
            >
              <div className=" absolute bottom-6 left-6 flex flex-col space-y-0 z-30 text-white">
                <h3 className="text-lg md:text-xl font-semibold">Crowdhaus</h3>
                <h4 className="text-sm md:text-base font-normal">
                  Property Discovery
                </h4>
              </div>
              <StaticImage
                alt="Crowdhaus App"
                placeholder="blurred"
                draggable="false"
                src="../images/crowdhaus.jpg"
                className="absolute h-full w-full object-cover z-20  group-hover:scale-105 transition ease-in-out duration-500"
              />
            </Link>
            {/* #1 END */}
            {/* #2 */}
            <Link
              to="/projects/drift/"
              className="group h-96 lg:h-[28rem] w-full lg:w-4/5 overflow-hidden relative cursor-pointer hover:scale-100 transition ease-in-out duration-500 "
            >
              <div className="absolute bottom-6 left-6 flex flex-col space-y-0 z-30">
                <h3 className="text-lg md:text-xl font-semibold">Drift</h3>
                <h4 className="text-sm md:text-base font-normal">
                  Improve Focus
                </h4>
              </div>

              <div className="absolute h-full w-full ">
                <div className="absolute h-full w-full flex justify-center items-center z-40">
                  <StaticImage
                    alt="Phone mockups with drift app open"
                    placeholder="blurred"
                    draggable="false"
                    src="../images/drift.png"
                    // group-hover:scale-105 transition ease-in-out duration-500
                    className="absolute bottom-0  left-0 h-full w-full object-cover group-hover:scale-105 transition ease-in-out duration-500"
                  />
                </div>
              </div>
              <StaticImage
                alt="Rainbow gradient background"
                placeholder="blurred"
                draggable="false"
                src="../images/driftBg.png"
                className="absolute h-full w-full object-fill z-20 group-hover:scale-105 transition ease-in-out duration-500 "
              />
            </Link>
            {/* #2 END */}
          </div>
          {/* Lower Row */}
          <div className="flex flex-col md:flex-row space-y-3 md:space-x-3 md:space-y-0 ">
            {/* #3 */}
            <Link
              to="/projects/coutre/"
              className="group h-96 lg:h-[28rem] w-full cursor-pointer overflow-hidden relative hover:scale-100 transition ease-in-out duration-500"
            >
              <div className=" absolute bottom-6 left-6 flex flex-col space-y-0 z-40 text-white">
                <h3 className="text-lg md:text-xl font-semibold">Coutre</h3>
                <h4 className="text-sm md:text-base font-normal">
                  Interior Design
                </h4>
              </div>
              <div className="absolute h-full w-full ">
                <div className="absolute h-full w-full bg-black opacity-40 z-30"></div>
                <div className="absolute h-full w-full flex justify-center items-center z-40">
                  <StaticImage
                    alt="Coutre Logo"
                    placeholder="blurred"
                    draggable="false"
                    src="../images/coutretext.png"
                    // group-hover:scale-105 transition ease-in-out duration-500
                    className="absolute w-72 object-scale-down"
                  />
                </div>
              </div>
              <StaticImage
                alt="Chair with a blanket on top"
                placeholder="blurred"
                draggable="false"
                src="../images/coutre.jpg"
                className="absolute h-full w-full object-cover z-20 group-hover:scale-105 transition ease-in-out duration-500"
              />
            </Link>
            {/* #3 END */}
            {/* #4 */}
            <Link
              to="/projects/snackspace/"
              className="group h-96 lg:h-[28rem] w-full cursor-pointer overflow-hidden relative hover:scale-100 transition ease-in-out duration-500"
            >
              <div className="absolute bottom-6 left-6 flex flex-col space-y-0 z-30 text-white">
                <h3 className="text-lg md:text-xl font-semibold">Snackspace</h3>
                <h4 className="ttext-sm md:text-base font-normal">
                  Discover restaurants
                </h4>
              </div>
              <StaticImage
                alt="Plate of food on a black background"
                placeholder="blurred"
                draggable="false"
                src="../images/snackspace.png"
                className="absolute h-full w-full object-cover z-20 group-hover:scale-105 transition ease-in-out duration-500"
              />
            </Link>
            {/* #4 END */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Projects;

// MARK: Optimised Image Download
// import { useStaticQuery, graphql } from "gatsby";
// import Img from "gatsby-image";

//   const { driftImage } = useStaticQuery(graphql`
//     query {
//       driftImage: file(relativePath: { eq: "drift.png" }) {
//         childImageSharp {
//           fluid(maxWidth: 2000) {
//             ...GatsbyImageSharpFluid
//           }
//         }
//       }
//     }
//   `);
// /* <Img
//     fluid={driftImage.childImageSharp.fluid}
//     imgStyle={{
//       objectFit: "fill",
//       objectPosition: "0 0 0 100px",
//     }}
//     alt="Drift"
//    /> */
