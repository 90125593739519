import React from "react";

type Props = {};

const Hero = (props: Props) => {
  return (
    <div className="w-full font-inter">
      <h1 className="text-4xl md:text-4xl lg:text-5xl font-semibold mt-9 md:mt-11">
        A full service studio providing incredible{" "}
        <br className="hidden  sm:block" /> design & development for startups
        <br className="hidden sm:block" /> around the world.
      </h1>
    </div>
  );
};

export default Hero;
