import React from "react";

type Props = {};

const OurStory = (props: Props) => {
  return (
    <>
      <a className="anchor" id="ourStory"></a>
      <div className="mt-11  md:mt-16 font-inter  md:flex md:space-x-4">
        <h2 className="font-semibold text-3xl md:w-1/2 lg:hidden">Our Story</h2>
        <div className="hidden w-1/2  lg:flex justify-between">
          <h2 className="font-semibold text-5xl">
            <span className="block">Our</span>
            <span className="block ml-16"> — Story </span>
          </h2>
          <div className="w-1.5 h-6 bg-black100"></div>
        </div>

        <p className="mt-7 md:mt-0 md:w-1/2">
          Welcome to Spring, a full-service studio founded in 2022. We offer a
          wide range of services, from branding and design to web development
          and digital marketing. We’re a team of passionate creatives who are
          dedicated to helping our clients grow their businesses and reach their
          goals.
          <br />
          <br />
          We believe that great design is essential to success in today’s
          competitive marketplace. We’re committed to creating beautiful,
          effective, and brand-building work that will help our clients stand
          out from the crowd.
          <br />
          <br />
          If you’re looking for a partner who can help you take your business to
          the next level, we’d love to chat with you. Contact us today to learn
          more about what we can do for you.
        </p>
      </div>

      <div className="w-full h-0 border border-borderLines  my-11 md:my-16 "></div>

      <div className="flex flex-col space-y-5 md:space-y-0 md:space-x-8 lg:space-x-28 md:flex-row ">
        {/* Design  */}
        <div className="flex flex-col space-y-4">
          <div className="flex space-x-3">
            <div className="w-1 h-3.5 bg-black100"></div>
            <h3 className="text-2xl lg:text-4xl font-semibold">Design</h3>
          </div>
          <p className="block">
            At Spring, we always start with great design. We like minimalism and
            putting content at the heart of your product, but whatever your
            vision is, we can help you to craft it into a reality.
          </p>
        </div>

        {/* Build  */}
        <div className="flex flex-col space-y-4 lg:pt-14">
          <div className="flex space-x-3">
            <div className="w-1 h-3.5 bg-black100"></div>
            <h3 className="text-2xl lg:text-4xl font-semibold">Build</h3>
          </div>
          <p className=" block">
            We develop extraordinary web and mobile experiences that share a
            cohesive feel. We build quickly, without sacrificing quality. Our
            small team works hand in hand with yours at every step of
            development.
          </p>
        </div>

        {/* Manage  */}
        <div className="flex flex-col space-y-4 lg:pt-28">
          <div className="flex space-x-3">
            <div className="w-1 h-3.5 bg-black100"></div>
            <h3 className="text-2xl lg:text-4xl font-semibold">Manage</h3>
          </div>
          <p className=" block">
            Once your product is shipped, Spring can be retained for new feature
            development, ongoing support and product marketing. We love to help
            startups grow their product.
          </p>
        </div>
      </div>
    </>
  );
};

export default OurStory;
