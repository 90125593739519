import * as React from "react";
import SEO from "../components/seo";

import Hero from "../components/Hero";
import Projects from "../components/Projects";
import RunningLine from "../components/RunningLine";
import OurStory from "../components/OurStory";
import ContactUs from "../components/ContactUs";
import { useEffect, useState } from "react";

const IndexPage = () => {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  } else {
    return (
      <>
        <SEO title="Spring - Full Service Studio" lang="en" />
        {/* <RunningLine /> */}
        <main className="text-black100 max-w-7xl px-6 lg:px-8 mx-auto  ">
          <Hero />
          <Projects />
          <OurStory />
          <ContactUs />
        </main>
      </>
    );
  }
};

export default IndexPage;