import React from "react";
import ContactForm from "./ContactForm";

type Props = {};

const ContactUs = (props: Props) => {
  return (
    <div>
      <a className="anchor" id="contactUs"></a>
      <div className="w-full h-0 border border-borderLines my-11 md:my-16"></div>
      <div className=" font-inter lg:flex">
        {/* Left */}
        <div className="w-full">
          <h2 className="font-semibold text-3xl lg:text-5xl">
            Got A <br className="hidden  lg:block" /> Project{" "}
            <br className="hidden  lg:block" /> In Mind?
          </h2>
          <p className="mt-7  lg:max-w-sm">
            Get in contact with us and let’s have a chat, there’s no commitment,
            and it’s completely free. You can meet the team, if you want… they
            don’t bite!
          </p>
        </div>
        {/* Right */}
        <ContactForm className="mt-7 lg:mt-0" />
      </div>
    </div>
  );
};

export default ContactUs;
